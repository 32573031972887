import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2><a parentName="h2" {...{
        "href": "https://anchor.fm/antonin-januska"
      }}>{`YAFF - Yet Another Final Fantasy Podcast`}</a></h2>
    <p>{`A podcast that follows my journey through the Final Fantasy franchise`}</p>
    <h2>{`ModelFlex (formerly Blogless)`}</h2>
    <p>{`A blogging/simple data backend with a graphl API.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      